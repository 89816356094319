import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import BlogPostGridItem from './BlogPostGridItem';

const BlogPostGrid = ({ blogPosts = [] }) => {
  return (
    <Box>
      {blogPosts.map((x, i) => (
        <BlogPostGridItem key={x.internalId} blogPost={x} i={i} />
      ))}
    </Box>
  );
};

BlogPostGrid.propTypes = {
  blogPosts: PropTypes.array,
};

export default memo(BlogPostGrid);
