import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Heading, Box, ThemeContext, Paragraph, Button } from 'grommet';

import ImgixImage from '../ImgixImage';
import { useStoryblokState } from '../../lib/storyblok';
import useMobile from '../useMobile';
import useRichText from '../useRichText';
import SmartLink from '../SmartLink';

const BlogPostGridItem = ({ blogPost, i }) => {
  const theme = useContext(ThemeContext);
  const isMobile = useMobile();
  const story = useStoryblokState(blogPost);
  const text = useRichText(story.content.excerpt);
  const direction = isMobile
    ? 'column-reverse'
    : i % 2 === 0
    ? 'row-reverse'
    : 'row';
  return (
    <Box
      key={blogPost._uid}
      direction={direction}
      height={isMobile ? 'auto' : { min: '50vw' }}
    >
      <Box flex={true} justify="center" pad="xlarge" basis="50%">
        {blogPost.category && (
          <SmartLink to={`/${blogPost.category.full_slug}`} plain>
            <Heading level={5}>{blogPost.category.name}</Heading>
          </SmartLink>
        )}
        <SmartLink to={`/${blogPost.full_slug}`} plain>
          <Heading level={2}>{story.content?.title}</Heading>
        </SmartLink>
        <Paragraph>{text}</Paragraph>
        <Button
          href={`/${blogPost.full_slug}/`}
          label="Read More"
          primary
          alignSelf="start"
        />
      </Box>
      <Box flex={true} height={isMobile ? 'auto' : { min: '50vw' }} basis="50%">
        <SmartLink to={`/${blogPost.full_slug}`} fill>
          <ImgixImage
            sizes={`(max-width: ${theme.global.breakpoints.small.value}px) 100vw, 50vw`}
            src={story.content?.headingMedia?.filename}
            srcSetOptions={{
              ar: '1:1',
              fit: 'crop',
              crop: 'entropy',
              auto: ['compress', 'format'],
            }}
            fill
            fit="cover"
            lazy={true}
          />
        </SmartLink>
      </Box>
    </Box>
  );
};

BlogPostGridItem.propTypes = {
  blogPost: PropTypes.object,
  i: PropTypes.number,
};

export default memo(BlogPostGridItem);
